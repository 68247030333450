import config from "@/config"
import { Auth } from "aws-amplify"
import * as AWS from "aws-sdk"
import store from "@/store"

const getDefaultState = () => {
  return {
    users: [],
    loading: false,
  }
}

const state = getDefaultState()

const getters = {
  users: (state) => state.users,
  loading: (state) => state.loading,
  user: (state) => (sub) => {
    return state.users.find(user => user.sub === sub)
  }
};

const actions = {
  async getUsers({ commit }) {
    store.dispatch('users/startLoading')
    const client = await Auth.currentCredentials().then(credentials => {
        return new AWS.CognitoIdentityServiceProvider({
          region: config.region,
          credentials: credentials
        })
    })
    var params = {
      UserPoolId: config.userPoolId,
      Filter: 'status = "Enabled"',
      Limit: '60'
    }
    try {
      const response = await client.listUsers(params).promise()
      var cookedUsers = []
      response.Users.forEach((user) => {
        // unroll {'Name': 'foo', 'Value': 'bar'}-style attributes
        user.Attributes.forEach((attr) => {
          user[attr.Name] = attr.Value
        })
        // ...and delete the Attributes dict
        delete user.Attributes
        if (!user.identities) {
          user.displayName = user.name ? user.name : user.email
          // user.identities will only be set on federated users,
          // we don't manage those here
          cookedUsers.push(user)
        }
      })
      commit('setUsers', cookedUsers)
    } catch (err) {
      console.log(err)
    }
    store.dispatch('users/endLoading')
  },
  startLoading({ commit }) {
    commit('setLoading', true)
  },
  endLoading({ commit }) {
    commit('setLoading', false)
  },
  resetState ({ commit }) {
    commit('resetState')
  },
};

const mutations = {
  setUsers(state, payload) {
    state.users = payload;
  },
  addUser(state, user) {
    state.users.push(user);
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  resetState (state) {
    Object.assign(state, getDefaultState())
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
